import Vue from 'vue';
import { reactive } from '@vue/composition-api';
import Store from './base/store';
import { Company } from "../models/company";
import { Location } from '../models/location';
import { Leader } from '../models/leader';

class CompanyStore extends Store<Company>{
  constructor(){
    super('company')
  }
  public get OfficeLocation() {
    return this._i18nedList[0]?.officeLocation.value[0] as Location ?? null;
  }
  public get Representative(){
    return this._i18nedList[0]?.representative.value[0] as Leader ?? null;
  }
}

export { CompanyStore, Company, Location, Leader };
