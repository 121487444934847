








































































































































































import dateFormat from 'dateformat';
import { Component, Vue, Watch } from 'vue-property-decorator';
import { reactive } from '@vue/composition-api';
import I18n from '@/i18n/index';
import { CompanyStore } from '@/repository/kontent/stores/companyStore';
import { Linkset, LinksetStore } from '@/repository/kontent/stores/sitemapStore';
import LocalNav from './LocalNav.vue';

@Component({
  components: {
    LocalNav,
  },
})
export default class Index extends Vue {
  private i18n = I18n;

  private created() {
    this.companyLink.setFilter('system.name', '企業情報');
    this.companyLink.setDepth(2);
    this.companyLink.restore();
    this.companyInfo.restore();
  }
  @Watch('i18n.language')
  onTextChanged(): void {
    this.companyLink = reactive(new LinksetStore());
    this.companyLink.setFilter('system.name', '企業情報');
    this.companyLink.setDepth(2);
    this.companyLink.restore();
    this.companyInfo = reactive(new CompanyStore());
    this.companyInfo.restore();
  }

  private companyLink = reactive(new LinksetStore());
  private companyInfo = reactive(new CompanyStore());

  private get officeLocation() {
    return this.companyInfo.OfficeLocation;
  }
  private get MissionTitle() {
    return this.companyInfo.list[0]?.mvTitle1.value;
  }
  private get MissionText() {
    return this.companyInfo.list[0]?.mvSubtitle1.value;
  }
  private get VisionTitle() {
    return this.companyInfo.list[0]?.mvTitle2.value;
  }
  private get VisionText() {
    return this.companyInfo.list[0]?.mvSubtitle2.value;
  }
  private get CompanyName() {
    return this.companyInfo.list[0]?.companyName.value;
  }
  private get BusinessDescription() {
    return this.companyInfo.list[0]?.businessDescription.value;
  }
  private get EstablishmentDate() {
    const dateval = this.companyInfo.list[0]?.date.value;
    if (this.$i18n.locale == 'ja') {
      return dateFormat(dateval || '', 'yyyy年m月d日');
    }
    return dateFormat(dateval || '', 'mmm dd, yyyy');
  }

  private get OfficeLocation() {
    // return CompanyStore.OfficeLocation.zipcode
    if (this.$i18n.locale == 'ja') {
      return `〒${this.companyInfo.OfficeLocation?.zipcode.value}
${this.companyInfo.OfficeLocation?.address.value}
電話 ${this.companyInfo.OfficeLocation?.phone.value}（代表）`;
    }
    return `${this.companyInfo.OfficeLocation?.address.value}
${this.companyInfo.OfficeLocation?.zipcode.value}
Phone: ${this.companyInfo.OfficeLocation?.phone.value}`;
  }
  private get Representative() {
    return `${this.companyInfo.Representative?.position.value} ${this.companyInfo.Representative?.name.value}`;
  }
  private get CapitalStock() {
    return this.companyInfo.list[0]?.capitalStock.value;
  }
  private get NumberOfEmployees() {
    return this.companyInfo.list[0]?.numberOfEmployees.value;
  }
  private get Credentials() {
    return this.companyInfo.list[0]?.credentials.value;
  }
  private get GooglemapUrl() {
    return this.companyInfo.OfficeLocation?.google_map_url.value;
  }
  private get AccessHeadline() {
    return this.companyInfo.list[0]?.accessHeadline.value.split('\n');
  }
  private get companyLinkSet() {
    return this.companyLink.list[0] as Linkset;
  }
  private get companyLinkList() {
    return this.companyLinkSet?.list.value;
  }
}
