




























import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { reactive } from '@vue/composition-api';
import { Linkset, LinksetStore } from '@/repository/kontent/stores/sitemapStore';
import I18n from '@/i18n/index';

@Component({
  components: {},
})
export default class LocalNav extends Vue {
  @Prop(Object) linkList!: Linkset;
  @Prop(String) selectTarget!: string;
  @Prop(Boolean) parentSet!: boolean;
  private i18n = I18n;

  private async created() {
    if (!this.parentSet) {
      this.getCompanyLink();
    }
  }
  @Watch('i18n.language')
  onTextChanged(): void {
    this.getCompanyLink();
  }

  private companyLink = reactive(new LinksetStore());
  private get companyLinkSet() {
    return this.linkList ?? (this.companyLink.list[0] as Linkset);
  }
  private get companyLinkList() {
    return this.companyLinkSet?.list.value;
  }
  private getCompanyLink() {
    this.companyLink = reactive(new LinksetStore());
    this.companyLink.setFilter('system.name', '企業情報');
    this.companyLink.setDepth(2);
    this.companyLink.restore();
  }
}
