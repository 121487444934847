
import { ContentItem, Elements } from '@kentico/kontent-delivery';

/**
 * Generated by '@kentico/kontent-model-generator@3.2.0'
 * Tip: You can replace 'ContentItem' with another generated class to fully leverage strong typing.
 */
export class Leader extends ContentItem {
    public name!: Elements.TextElement;
    public picture!: Elements.AssetsElement;
    public license!: Elements.TextElement;
    public positionType!: Elements.TaxonomyElement;
    public position!: Elements.TextElement;
    public specialty!: Elements.TextElement;
    public history!: Elements.LinkedItemsElement<ContentItem>;
    public message!: Elements.TextElement;
    constructor() {
        super({
            propertyResolver: ((elementName: string) => {
                if (elementName === 'position_type') {
                    return 'positionType';
                }
                return elementName;
            })
        });
    }
}
